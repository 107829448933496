import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import stylesTHB from './styles/top-home-banner-mobile-landscape-image';
import TopHomeBannerGeneric from './top-home-banner-generic';
import { resizeObserver } from './utils/resize-observer';

@customElement('top-home-banner-mobile-landscape-image')
export default class TopHomeBannerMobileLandscapeImage extends TopHomeBannerGeneric {
  static styles = [stylesTHB];

  protected firstUpdated(): void {
    super.firstUpdated();

    const container = this.shadowRoot?.querySelector('.thb-container') as HTMLElement;
    this.style.setProperty('--container-width', `${container.offsetWidth}px`);

    const resizeObserverInstance = resizeObserver(this.style);
    resizeObserverInstance.observe(container);
  }

  render() {
    return html`
      <a class="content-target" @click="${() => this.handlelink()}">
        ${this.renderTemplate()}
      </a>
    `;
  }
}
