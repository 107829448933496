import { html } from 'lit';
import { styleMap } from 'lit/directives/style-map.js';
import BaseBanner from './base-banner';

export default class TopHomeBannerGeneric extends BaseBanner {
  renderLogos() {
    const styleLogo = this.secondary_logo ? 'thb-logo-double' : 'thb-logo';
    return html`
      <div class="thb-container-${this.primary_logo ? 'logo' : 'no-logo'}">
          ${this.primary_logo && this.renderImage(styleLogo, this.primary_logo)}
          ${this.secondary_logo && this.renderImage(styleLogo, this.secondary_logo)}
      </div>
    `;
  }

  renderTexts() {
    return html`
      <div class="thb-texts-container">
        ${this.volanta ? html`<span class="thb-text-volanta">${this.volanta}</span>` : html``}
        <span class="thb-text">${this.primary_title}</span>
        <span class="thb-text">${this.secondary_title}</span>
        ${this.renderLegalesCta()}
      </div>
    `;
  }

  renderLegalesCta() {
    const legal = html`<span class="thb-text-legal">${this.legal}</span>`;
    const cta = this.cta ? html`<span class="thb-text-cta">${this.cta}</span>` : html``;
    return this.legal ? legal : cta;
  }

  renderTemplate() {
    return html`
      <div class="thb-container">
        <div class="thb-left-side" style=${styleMap({ color: this.card_text_color, background: this.card_background_color })}>
          <div class="thb-left-top-side">
            ${this.renderLogos()}
            ${this.renderTexts()}
          </div>
        </div>
        <div class="thb-right-side">
          <div class="thb-image-container">
            ${this.picture && this.renderImage('thb-picture', this.picture)}
          </div>
        </div>
      </div>
    `;
  }
}
