import { css } from 'lit';

const stylesTopHomeBanner = css`
  :host {
    --container-width: 0px; /* Default Value  */
    --dynamic-1: calc(var(--container-width)*1/328);
    --dynamic-4: calc(var(--container-width)*4/328);
    --dynamic-5p: calc(var(--container-width)*5.5/328);
    --dynamic-6: calc(var(--container-width)*6/328);
    --dynamic-8: calc(var(--container-width)*8/328);
    --dynamic-11: calc(var(--container-width)*11/328);
    --dynamic-13: calc(var(--container-width)*13/328);
    --dynamic-15: calc(var(--container-width)*15/328);
    --dynamic-27: calc(var(--container-width)*27/328);
    --dynamic-32: calc(var(--container-width)*32/328);
    --dynamic-36: calc(var(--container-width)*36/328);
    --dynamic-114: calc(var(--container-width)*114/328);
  }
    
  .thb-container {
    display: flex;
    width: 100%;
    position: relative;
  }

  .thb-container::before {
    display: block;
    content: '';
    width: 100%;
    padding-bottom: calc(100% / (var(--aspect-ratio, 4.1 / 1)));
  }

  .content-target {
    text-decoration: none;
    cursor: pointer;
  }

  .thb-left-side {
    position: absolute;
    top: 0;
    left: 0;
    width: 53%;
    height: 100%;
    display: flex;
    justify-content: center;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .thb-container-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: var(--dynamic-8);
    gap: var(--dynamic-4);
  }

  .thb-container-no-logo {
  }

  .thb-right-side {
    width: 47%;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    overflow: hidden;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .thb-image-container {
    aspect-ratio: 1.91 / 1;
    display: flex;
    justify-content: center;
  }

  .thb-picture {
    width: 100%;
    height: auto;
  }

  .thb-logo {
    width: var(--dynamic-36);
    height: var(--dynamic-27);
    border-radius: 4px;
    object-fit: contain;
    align-self: center;
    background-color: white;
    padding: var(--dynamic-1);
  }

  .thb-logo-double {
    width: var(--dynamic-32);
    height: var(--dynamic-27);
    border-radius: 4px;
    object-fit: contain;
    align-self: center;
    background-color: white;
    padding: var(--dynamic-1);
  }

  .thb-texts-container {
    display: flex;
    justify-content: center;
    max-width: var(--dynamic-114);
    flex-direction: column;
    position: relative;
  }

  .thb-text {
    font-size: var(--dynamic-11);
    line-height: var(--dynamic-13);
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .thb-text-volanta {
    font-size: var(--dynamic-8);
    line-height: var(--dynamic-8);
    font-weight: 500;
    letter-spacing: var(--dynamic-1);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    margin-bottom: var(--dynamic-4);
  }

  .thb-text-cta {
    font-size: var(--dynamic-8);
    line-height: var(--dynamic-8);
    font-weight: 600;
    letter-spacing: var(--dynamic-1);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    margin-top: var(--dynamic-8);
  }

  .thb-text-legal {
    font-size: var(--dynamic-5p);
    line-height: var(--dynamic-8);
    font-weight: 400;
    margin-top: var(--dynamic-6);
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: var(--dynamic-114);
    height: var(--dynamic-15);
  }

  .thb-left-top-side {
    display: flex;
  }
`;

export default stylesTopHomeBanner;
